import React, { useEffect } from 'react';

const AdsCard = ({ slot, format = 'auto', layoutKey = null }) => {

  const id = "ca-pub-7442396223481118"

  useEffect(() => {
    if (window.adsbygoogle && process.env.NODE_ENV !== "development") {
      window.adsbygoogle.push({});
    }
  }, [])

  if (layoutKey) {
    return (
      <ins className="adsbygoogle"
        style={{ display: `block`, minWidth: `250px`, minHeight: `250px` }}
        data-ad-client={id}
        data-ad-slot={slot}
        data-ad-format={format}
        data-ad-layout-key={layoutKey}
        data-full-width-responsive="true" />
    )
  }
  else {
    return(
      <div className='googleAds'>
        <ins className="adsbygoogle"
          style={{ "display": "block" }}
          data-ad-client={id}
          data-ad-slot={slot}
          data-ad-format={format}
          data-full-width-responsive="false" />
      </div>
    )
  }
}

export default AdsCard