import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { makeStyles } from '@material-ui/core/styles'

import axios from 'axios'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'

import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'

import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment';

const Header = ({ pageTitle, size }) => {

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      main: file(absolutePath: { regex: "/avatar.png/" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          title
          author {
            name
            summary
            degree
          }
        }
      }
    }
  `)

  const siteTitle = data.site.siteMetadata.title
  const { author } = data.site.siteMetadata
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const loading = open && options.length === 0

  React.useEffect(() => {
    let active = true

    if (!loading) {
      return undefined
    }

    (async () => {
      const response = await axios.get('/search.json')
      if (active) {
        setOptions(response.data)
      }
    })()

    return () => {
      active = false
    }
  }, [loading])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  const onSearch = (e, v) => {
    if (v !== null) {
      navigate(v.path)
    }
  }

  const truncate = (str, len) => {
    return str.length <= len ? str: (str.substr(0, len)+"...");
  }

  return (
    <header className={classes.root}>
      <Container>

        <div className={classes.topArea}>
          <Tooltip title="Go Home" arrow>
            <Link to="/" className={classes.link}>
              <h2 className={classes.title}>
                {siteTitle}
                <Typography className={classes.subTitle} variant="subtitle1" component="div">{author.name}@{author.degree} / {author.summary}</Typography>
              </h2>
            </Link>
          </Tooltip>
          <div>
            <div className={classes.search}>
              <Autocomplete
                id="search"
                freeSolo
                size="small"
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                getOptionSelected={(option, value) => option.name === value.title}
                getOptionLabel={(option) => option.title}
                options={options}
                loading={loading}
                onChange={onSearch}
                renderInput={(params) => (

                  <TextField
                    {...params}
                    variant="outlined"
                    label="記事 検索"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className='side-label'>
          <PlaceOutlinedIcon /> <Tooltip title="Go Home" arrow><Link className={classes.link} to="/">Top</Link></Tooltip> {pageTitle !== 'Top' ? ` / ${truncate(pageTitle, 1000)}` : ""}
        </div>
      </Container>
    </header>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: `black`,
    borderColor: 'black',
    borderTop: '0',
    borderLeft: '0',
    borderRight: '0',
    backgroundColor: `#fff`,
  },
  topArea: {
    margin: `0 auto`,
    maxWidth: `100%`,
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  link: {
    color: `black`,
    textDecoration: `none`,
    boxShadow: `none`,
  },
  search: {
    width: 308,
  },
  link2: {
    color: `white`,
  },
  title: {
    display: `inline-block`,
    verticalAlign: `bottom`,
    [theme.breakpoints.up('sm')]: {
      marginRight: '5px',
    },
    marginTop: `1rem`,
    marginBottom: `1rem`,
  },
  subTitle: {
    fontSize: `15px`,
    color: `rgba(0, 0, 0, 0.87)`,
  },
  avatar: {
    borderRadius: `100%`,
    border: `7px solid white`,
    marginBottom: `1.5rem`,
  },
  whats: {
    textAlign: `center`,
    color: `white`,
  },
  popover: {
    maxWidth: `520px`,
    padding: theme.spacing(3),
    background: `#00b0ff`,
    color: `white`,
  },
  icon: {
    fontSize: 40,
    color: `white`,
  },
}))

Header.defaultProps = {
  pageTitle: `Home`,
  size: `normal`
}

Header.propTypes = {
  pageTitle: PropTypes.string,
  size: PropTypes.string,
}

export default Header