/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import CssBaseline from '@material-ui/core/CssBaseline'

import Header from "./header"
import Footer from "./footer"

import { ThemeProvider } from '@material-ui/styles'
import { createGlobalStyle } from "styled-components"
import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: `#00b0ff`,
    },
  },
})

const Layout = ({ location, title, children, headerSize }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyle />
        <Header pageTitle={title} size={headerSize}/>
        <main>{children}</main>
        <Footer />
      </ThemeProvider>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: aktiv-grotesk,source-han-sans-japanese,sans-serif;
    background-color: #f5f5f5;
  }
  section {
    margin: 80px 0;
  }
  img {
    margin-bottom: 0;
  }
  .side-label {
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    letter-spacing: 1px;
    position: absolute;
    top: 80px;
    right: 10px;
    -webkit-transform: translateY(-50%)
    -ms-transform: translateY(-50%)
    transform: translateY(-50%)
    margin: auto;
    color: black;
    z-index: 100;
  }
  ins {
    background-color : rgb(0,0,0,0);
  }
  a {
    box-shadow: none;
    color: #007acc;
    text-decoration: none;
  }

`
export default Layout