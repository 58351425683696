import React from "react"
import Aws from 'aws-sdk'

import { makeStyles } from '@material-ui/core/styles'
import { Link, useStaticQuery, graphql } from "gatsby"
import AdsCard from "../components/adsCard"

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'

import LinkIcon from '@material-ui/icons/Link'
import MailIcon from '@material-ui/icons/Mail'
import CreateIcon from '@material-ui/icons/Create'
import NoteIcon from '@material-ui/icons/Note'
import TwitterIcon from '@material-ui/icons/Twitter'
import InstagramIcon from '@material-ui/icons/Instagram'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined'
import PhotoAlbumOutlinedIcon from '@material-ui/icons/PhotoAlbumOutlined'
import WorkOutlinedIcon from '@material-ui/icons/WorkOutlined'
import HomeIcon from '@material-ui/icons/Home'
import LockIcon from '@material-ui/icons/Lock'
import SendIcon from '@material-ui/icons/Send'

import Snackbar from '@material-ui/core/Snackbar'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import RssFeedIcon from '@material-ui/icons/RssFeed'

Aws.config.region = 'us-east-1'
Aws.config.credentials = new Aws.CognitoIdentityCredentials({
    IdentityPoolId: 'us-east-1:1e57b1f3-6aab-476b-95a0-ba28822708a9',
})

const Footer = ({ title }) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          author {
            name
            summary
            degree
          }
          social {
            twitter
            note
            instagram
          }
        }
      }
      avatar: file(absolutePath: { regex: "/avatar.png/" }) {
        childImageSharp {
          fixed(width: 130, height: 130) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata

  const classes = useStyles()

  const isSLack = false;
  const [mail, setMail] = React.useState("")
  const [name, setName] = React.useState("")
  const [body, setBody] = React.useState("")

  const defaultMessage = "入力して下さい"
  const [mailMessage, setMailMessage] = React.useState(defaultMessage)
  const [nameMessage, setNameMessage] = React.useState(defaultMessage)
  const [bodyMessage, setBodyMessage] = React.useState(defaultMessage)
  const [load, setLoad] = React.useState(false)

  const handleChangeMail = (event) => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value)) {
      setMailMessage("メールアドレスが正しくありません")
    }
    else {
      setMail(event.target.value)
      setMailMessage("")
    }
  }
  const handleChangeName = (event) => {
    if (event.target.value === "") {
      setNameMessage("名前を入力して下さい")
    }
    else {
      setName(event.target.value)
      setNameMessage("")
    }
  }
  const handleChangeBody = (event) => {
    if (event.target.value === "") {
      setBodyMessage("内容を入力して下さい")
    }
    else {
      setBody(event.target.value)
      setBodyMessage("")
    }
  }

  const canSubmitButton = () => {
    if (mailMessage === "" && nameMessage === "" && bodyMessage === "") {
      return false
    }
    else {
      return true
    }
  }

  const getMessage = () => {
    if (mailMessage !== "") { return mailMessage }
    else if (nameMessage !== "") { return nameMessage }
    else if (bodyMessage !== "") { return bodyMessage }
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    setLoad(true)

    let s3BucketName = "blog-form"

    let now = new Date()
    let obj = {"name": name, "email": mail , "body": body, "date": now.toLocaleString()}
    let s3 = new Aws.S3({params: {Bucket: s3BucketName}})
    let blob = new Blob([JSON.stringify(obj, null, 2)], {type:'text/plain'});
    s3.putObject({
      Key: "uploads/" +now.getTime()+".txt",
      ContentType: "text/plain",
      Body: blob,
      ACL: "public-read"
    }, (err, data) => {
      setLoad(false)
      setSnackOpen(true)

      if(data !== null) {
        // 成功処理
        setSnackMessage("送信しました。お問い合わせありがとうございます。返信までしばらくお待ち下さい。")
      }
      else{
        // 失敗処理
        setSnackMessage("送信に失敗しました。しばらく経ってから再度お試し下さい。")
      }
    })
  }

  const [snackOpen, setSnackOpen] = React.useState(false)
  const [snackMessage, setSnackMessage] = React.useState("")

  const handleSnackClose = () => {
    setSnackOpen(false)
  }

  return (
    <footer className={classes.root}>
      <Container>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={4}
          className={classes.sns}
        >
          <Grid item sm={12} xs={12} key="twitter">

          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={4}
          className={classes.sns}
        >
          <Grid item sm={4} xs={12} key="tags">
            <Typography variant="h5" component="h5" className={classes.title}><MailIcon />CONACT</Typography>
            <Paper >
              <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                <TextField id="outlined-basic" label="メールアドレス" variant="outlined" name="mail" onChange={handleChangeMail}/>
                <TextField id="outlined-basic2" label="お名前" variant="outlined"  name="name" onChange={handleChangeName}/>
                <TextField
                  id="outlined-multiline-static"
                  label="内容"
                  multiline
                  rows={7}
                  variant="outlined"
                  name="body"
                  onChange={handleChangeBody}
                />
                <Typography variant="body2" color="primary" component="div">
                  {
                    getMessage()
                  }
                </Typography>


                <Button disabled={canSubmitButton() || load} type="submit" className={classes.button} variant="contained" size="large" color="primary">
                  {load && <CircularProgress size={24} className={classes.link} />}{!load && <SendIcon className={classes.link}/>}<span style={{marginLeft: '5px', color: `white`}}>送信する</span>
                </Button>
              </form>
            </Paper>
            {
              isSLack ? (
              <div>
                <Button variant="contained" size="large" className={classes.slackButton} href="https://join.slack.com/t/ukai-stay-free/shared_invite/zt-ejxs0yf8-y~H4HlyV1zUS9ziSlTkzag">
                  <SendIcon className={classes.link} /><span style={{marginLeft: '5px', color: `white`}}>Slack からもどうぞ</span>
                </Button>
                <p>※お気軽にどうぞ！（６月２０日まで有効！お早めに）</p>
              </div>
              ) : ""
            }

          </Grid>
          <Grid item sm={4} xs={12} key="link">
            <Typography variant="h5" component="h5" className={classes.title}><LinkIcon />NAVIGATION</Typography>
            <Paper className={classes.paper}>
              <MenuList>
                <Link to="/">
                  <MenuItem key="home">
                    <ListItemIcon>
                      <HomeIcon className={classes.link} fontSize="large" />
                    </ListItemIcon>
                    <Typography className={classes.link} variant="inherit">TOP</Typography>
                  </MenuItem>
                </Link>
                <Link to="/about/">
                  <MenuItem>
                    <ListItemIcon key="about">
                      <LiveHelpOutlinedIcon className={classes.link} fontSize="large" />
                    </ListItemIcon>
                    <Typography className={classes.link} variant="inherit">ABOUT</Typography>
                  </MenuItem>
                </Link>
                <Link to="/work/">
                  <MenuItem key="work">
                    <ListItemIcon>
                      <WorkOutlinedIcon className={classes.link} fontSize="large" />
                    </ListItemIcon>
                    <Typography className={classes.link} variant="inherit">WORK</Typography>
                  </MenuItem>
                </Link>
                <Link to="/gallery/">
                  <MenuItem key="gallery">
                    <ListItemIcon>
                      <PhotoAlbumOutlinedIcon className={classes.link} fontSize="large" />
                    </ListItemIcon>
                    <Typography className={classes.link} variant="inherit">GALLERY</Typography>
                  </MenuItem>
                </Link>
                <Link to="/privacy/">
                  <MenuItem key="privacy">
                    <ListItemIcon>
                      <LockIcon className={classes.link} fontSize="large" />
                    </ListItemIcon>
                    <Typography className={classes.link} variant="inherit">PRIVACY & TERMS</Typography>
                  </MenuItem>
                </Link>
                <Link to="/rss.xml">
                  <MenuItem key="rss">
                    <ListItemIcon>
                      <RssFeedIcon className={classes.link} fontSize="large" />
                    </ListItemIcon>
                    <Typography className={classes.link} variant="inherit">RSS</Typography>
                  </MenuItem>
                </Link>
              </MenuList>
            </Paper>
          </Grid>
          <Grid item sm={4} xs={12} key="write-by">
            <Typography variant="h5" component="h5" className={classes.title}><CreateIcon />WRITTEN BY</Typography>
            <Card className={classes.card}>
              <CardHeader
                avatar={
                  <Avatar className={classes.avatar} src={data.avatar.childImageSharp.fixed.src} alt={`${author.name} / ${author.summary}`} />
                }
                title={`${author.name}@${author.degree}`}
                subheader={<span style={{color: `white`}}>{author.summary}</span>}
                className={classes.cardHeader}
              />
              <CardContent>
                <Typography variant="body1" component="h4" className={classes.content}>
                  Nobuyuki Ukai
                </Typography>
                <Typography variant="body2" component="p" className={classes.content}>
                  {author.summary} 代表取締役CEO。建築を専攻していたが、新卒でYahoo!Japanにエンジニアとして入社。その後、転職、脱サラ、フリーランスエンジニアを経て、田舎へ移住し、ゲストハウスの開業、法人成り。ゲストハウス２軒、焼肉店、カフェ、食品製造業を運営しています。2020年コロナウイルスの影響で、ゲストハウスとカフェを閉店。現在は、ECサイト新規リリース、運営と、黒毛和牛の牝牛ブランディングをしメディア立ち上げ。牝牛のお肉と、独自食品開発した食品をまもなく販売開始予定。詳細は<Link style={{color: `white`}} to="/about/">こちら</Link>から
                </Typography>
                <div className={classes.cardHeader}>
                  <a className={classes.link} href={`https://twitter.com/${social.twitter}`} rel="noopener noreferrer" target="_blank">
                    <Tooltip title="Go Twitter"><IconButton><TwitterIcon className={classes.link}/></IconButton></Tooltip>
                  </a>
                  <a className={classes.link} href={`https://www.instagram.com/${social.instagram}`} rel="noopener noreferrer" target="_blank">
                    <Tooltip title="Go Instagram"><IconButton><InstagramIcon className={classes.link}/></IconButton></Tooltip>
                  </a>
                  <a className={classes.link} href={`https://note.com/${social.note}`} rel="noopener noreferrer" target="_blank">
                    <Tooltip title="Go Note"><IconButton><NoteIcon className={classes.link}/></IconButton></Tooltip>
                  </a>
                </div>
              </CardContent>
            </Card>

            <React.Fragment>
              <a href="https://px.a8.net/svt/ejp?a8mat=3H5UZF+1JDC1E+4754+5ZMCH" rel="noopener noreferrer sponsored" target="_blank">
                <img border="0" width="300" height="250" alt="" src="https://www25.a8.net/svt/bgt?aid=210225435093&wid=001&eno=01&mid=s00000019588001006000&mc=1" />
              </a>
              <img border="0" width="1" height="1" src="https://www19.a8.net/0.gif?a8mat=3H5UZF+1JDC1E+4754+5ZMCH" alt=""/>
            </React.Fragment>
          </Grid>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={snackOpen}
            onClose={handleSnackClose}
            message={snackMessage}
          />
        </Grid>
        <AdsCard slot="9009456012" />
        <Typography variant="body2" gutterBottom className={classes.copyright}>
          COPYRIGHT © {new Date().getFullYear()}
          {` `}
          <a href="https://ukai-mnap.co.jp" rel="noopener noreferrer" target="_blank" className={classes.link}>UKAI CO., LTD.</a> ALL RIGHTS RESERVED.
        </Typography>
      </Container>
    </footer>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(5),
    background: `#00b0ff`,
    textAlign: 'center',
    color: "white",
  },
  title: {
    paddingBottom: theme.spacing(5),
  },
  link: {
    color: "white",
    boxShadow: `none`,
  },
  card: {
    background: `#00b0ff`,
    boxShadow: `none`,
  },
  cardHeader: {
    color: `white`,
    textAlign: `left`,
  },
  content: {
    color: `white`,
    textAlign: `left`,
  },
  sns: {
    textAlign: `center`,
    paddingBottom: theme.spacing(5),
    color: `white`,
  },
  avatar: {
    borderRadius: `100%`,
    border: `4px solid white`,
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  paper: {
    paddingTop: theme.spacing(1),
    background: `#00b0ff`,
    boxShadow: `none`,
  },
  form: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(1),
      width: `90%`,
    },
    button: {
      margin: theme.spacing(1),
      color: `white`
    },
    buttonWrapper: {
      position: 'relative',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  },
  slackButton: {
    margin: theme.spacing(1),
    color: `white`,
    width: '90%',
    backgroundColor: `rgb(255, 82, 82)`,

  },
  copyright: {
    paddingTop: theme.spacing(5),
  },
}))

export default Footer
